var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('l-map',{attrs:{"zoom":_vm.zoom,"center":_vm.filters.center,"bounds":_vm.filters.bounds},on:{"update:zoom":function($event){_vm.zoom=$event},"update:center":function($event){return _vm.$set(_vm.filters, "center", $event)},"update:bounds":_vm.boundsUpdated}},[_c('l-icon-default'),_c('l-control-fullscreen',{attrs:{"position":"topleft"}}),_c('l-tile-layer',{attrs:{"url":_vm.url,"attribution":_vm.attribution}}),_c('l-marker-cluster',{ref:"cluster",attrs:{"options":_vm.clusterOptions}},_vm._l((_vm.clusters),function(cluster){return _c('l-marker',{key:cluster.itemIds[0],attrs:{"lat-lng":_vm.computeLatLng(cluster.lat, cluster.lng),"options":{ count: cluster.singlePoint ? 1 : cluster.count },"icon":_vm.icon},on:{"click":function($event){cluster.singlePoint
            ? _vm.openPopup($event, cluster)
            : _vm.zoomOnPoint($event, cluster)},"popupclose":function($event){return _vm.onPopupClose()}}},[(cluster.singlePoint)?_c('l-popup',[_c('swiper',{staticClass:"swiper-navigations",attrs:{"id":"swiper","options":_vm.swiperOptions,"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr'}},[_vm._l((_vm.propertiesPopedUp),function(property,index){return _c('swiper-slide',{key:index},[_c('b-card',{staticClass:"mb-0",attrs:{"no-body":""},on:{"click":function($event){_vm.newTabTo(
                    _vm.$router.resolve({
                      name: "realestate-property",
                      query: {
                        propertyID: property.id,
                      },
                    }).href
                  )}}},[_c('img',{staticClass:"card-popup-img",attrs:{"src":_vm.propertyImage(property),"alt":"card img"},on:{"error":function($event){return _vm.imageLoadError(property.id)}}}),_c('b-card-body',[_c('b-card-title',{staticClass:"font-weight-bolder mb-0"},[_vm._v(_vm._s(_vm.translateTypeParent(property.type_parent))+" - "+_vm._s(property.rooms.nb_rooms)+"p - "+_vm._s(property.area.area)+"m² ")]),_c('b-card-text',{staticClass:"font-weight-bolder mt-1"},[_vm._v(" "+_vm._s(_vm.currency(property.price))+" - "+_vm._s(_vm.currency(property.price / property.area.area))+"/m² ")])],1)],1)],1)}),_c('div',{staticClass:"swiper-button-next",attrs:{"slot":"button-next"},slot:"button-next"}),_c('div',{staticClass:"swiper-button-prev",attrs:{"slot":"button-prev"},slot:"button-prev"})],2)],1):_vm._e()],1)}),1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }