<template>
  <div>
    <p class="cluster-text"></p>
  </div>
</template>

<script>
export default {};
</script>

<style>
.cluster-img {
  float: left;
  width: 60px;
  height: 60px;
}
.cluster-text {
  margin-left: -20px;
  margin-top: -20px;
  width: 20px;
  height: 20px;
  border-radius: 100%;
  background-color: rgba(59, 118, 167, 0.6);
  color: #fff;
  text-align: center;
  font-size: 14px;
  overflow: hidden;
  line-height: 40px;
}
</style>
