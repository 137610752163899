<template>
  <b-card no-body class="card-select-estimate">
    <b-card-body class="py-50">
      <b-row>
        <b-col cols="12">
          <b-form-group
            label="Rechercher une adresse"
            label-for="selected"
            class="mb-50"
          >
            <vue-autosuggest
              id="selected"
              :suggestions="suggestedAddressOptions"
              :limit="4"
              :input-props="{
                id: 'autosuggest__input',
                class: 'form-control',
                placeholder: '51, Rue du Marché 69300 Caluire-et-Cuire',
              }"
              @input="onInputChange"
              @selected="onSelected"
              @blur="onBlur"
              :get-suggestion-value="getSuggestionValue"
              v-model="filters.address"
            >
              <template slot-scope="{ suggestion }">
                <span class="my-suggestion-item">{{
                  suggestion.item.properties.label
                }}</span>
              </template>
            </vue-autosuggest>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row class="text-center mx-50">
        <b-dropdown
          variant="outline-primary"
          toggle-class="d-flex align-items-center"
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          size="sm"
          class="pr-50 py-50"
        >
          <template #button-content>
            <p class="font-weight-bolder mb-0">
              {{ filters.searchMode === "buy" ? "Acheter" : "Louer" }}
            </p>
          </template>
          <b-dropdown-item @click="modeSelected('buy')">
            Acheter
          </b-dropdown-item>
          <b-dropdown-item @click="modeSelected('rent')">
            Louer
          </b-dropdown-item>
        </b-dropdown>

        <b-dropdown
          variant="outline-primary"
          toggle-class="d-flex align-items-center"
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          size="sm"
          class="pr-50 py-50"
        >
          <template #button-content>
            <p class="font-weight-bolder mb-0">
              {{
                filters.minPrice || filters.maxPrice
                  ? evaluateLabelPrice()
                  : "Prix"
              }}
            </p>
          </template>
          <b-dropdown-form class="dropdown-menu">
            <b-form-group label="Prix minimum" label-for="minPrice">
              <b-input-group prepend="€">
                <b-form-input
                  placeholder="120 000"
                  type="number"
                  v-model="filters.minPrice"
                />
              </b-input-group>
            </b-form-group>

            <b-form-group label="Prix maximum" label-for="maxPrice">
              <b-input-group prepend="€">
                <b-form-input
                  placeholder="240 000"
                  type="number"
                  v-model="filters.maxPrice"
                />
              </b-input-group>
            </b-form-group>
          </b-dropdown-form>
        </b-dropdown>

        <b-dropdown
          toggle-class="d-flex align-items-center"
          size="sm"
          id="dropdown-dropright"
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          dropright
          text="Drop-Right"
          variant="primary"
          class="py-50"
          @show="openModalFilters($event)"
        >
          <template #button-content>
            <p class="font-weight-bolder mb-0">
              {{
                filters.numberFilters !== 0
                  ? "Filtres - " + filters.numberFilters
                  : "Filtres"
              }}
            </p>
          </template>
        </b-dropdown>
      </b-row>

      <!-- modal -->
      <b-modal
        ref="modal-list-filter"
        id="modal-list-filter"
        size="lg"
        title="Filtres"
        content-class="modal-height"
        ok-title="Appliquer"
        no-stacking
      >
        <div class="d-flex mt-1">
          <h5 class="mb-0 ml-50">Type</h5>
        </div>
        <b-button-group class="mt-2 mb-1 mr-2 ml-2" size="lg">
          <b-button
            v-for="option in filterOptions.typesOptions"
            :key="option.value"
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            :variant="
              filters.types.includes(option.value)
                ? 'primary'
                : 'outline-primary'
            "
            @click="typesSelected(option.value)"
          >
            <feather-icon size="20" :icon="option.icon" />
            <span class="mt-2">{{ option.label }}</span>
          </b-button>
        </b-button-group>

        <hr />

        <div class="d-flex mt-1">
          <h5 class="mb-0 ml-50">Surface</h5>
        </div>
        <vue-slider
          v-model="filters.areas"
          :order="true"
          :tooltip="'always'"
          :min="0"
          :max="250"
          :min-range="20"
          class="mt-4 mb-1 mr-4 ml-4"
          :tooltip-formatter="formatter"
        />

        <hr />

        <b-row>
          <b-col cols="6">
            <div class="d-flex mt-1">
              <h5 class="mb-0 ml-50">Nombre de pièces</h5>
            </div>

            <b-button-group class="mt-2 mb-1 mr-1 ml-1" size="lg">
              <b-button
                v-for="option in filterOptions.roomsOptions"
                :key="option.value"
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                :variant="
                  filters.rooms.includes(option.value)
                    ? 'primary'
                    : 'outline-primary'
                "
                @click="roomsSelected(option.value)"
              >
                <span>{{ option.label }}</span>
              </b-button>
            </b-button-group>
          </b-col>
          <b-col cols="6">
            <div class="d-flex mt-1">
              <h5 class="mb-0 ml-50">Nombre de chambres</h5>
            </div>
            <b-button-group class="mt-2 mb-1 mr-1 ml-1" size="lg">
              <b-button
                v-for="option in filterOptions.roomsOptions"
                :key="option.value"
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                :variant="
                  filters.bedRooms.includes(option.value)
                    ? 'primary'
                    : 'outline-primary'
                "
                @click="bedroomsSelected(option.value)"
              >
                <span>{{ option.label }}</span>
              </b-button>
            </b-button-group>
          </b-col>
        </b-row>

        <hr />

        <b-row>
          <b-col cols="6">
            <div class="d-flex mt-1">
              <h5 class="mb-0 ml-50">Catégorie</h5>
            </div>
            <b-form-radio-group
              v-model="filters.category"
              :options="filterOptions.categorieOptions"
              value-field="value"
              text-field="label"
              class="mt-2 ml-2"
            />
          </b-col>
          <b-col cols="6">
            <div class="d-flex mt-1">
              <h5 class="mb-0 ml-50">Flux</h5>
            </div>
            <v-select
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              v-model="filters.flowOrigin"
              :options="filterOptions.flowOriginOptions"
              :clearable="true"
              class="w-100 mt-1"
            />
          </b-col>
        </b-row>
      </b-modal>
    </b-card-body>
  </b-card>
</template>

<script>
import VueSlider from "vue-slider-component";
import vSelect from "vue-select";
import { VueAutosuggest } from "vue-autosuggest";
import { latLng } from "leaflet";
import { useRealestateAPI } from "../useRealestate";
import {
  BCard,
  BCardBody,
  BRow,
  BCol,
  BFormGroup,
  BDropdown,
  BDropdownItem,
  BModal,
  BFormRadioGroup,
  BInputGroup,
  BFormInput,
  BDropdownForm,
  BButton,
  BButtonGroup,
  VBModal,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import _ from "lodash";
import { currency } from "@core/utils/filter";

export default {
  components: {
    VueSlider,
    BCard,
    BCardBody,
    BRow,
    BCol,
    BFormGroup,
    BDropdown,
    BDropdownItem,
    BModal,
    BFormRadioGroup,
    BInputGroup,
    BFormInput,
    BDropdownForm,
    BButton,
    BButtonGroup,
    VueAutosuggest,
    vSelect,
  },
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  props: {
    filters: {
      type: Object,
      required: true,
    },
    filterOptions: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      lastSelectedAddress: this.filters.address,
      formatter: "{value} m²",
      suggestedAddressOptions: [],
    };
  },
  watch: {
    "filters.areas"(val, oldVal) {
      if (
        (val[0] !== 0 || val[1] !== 250) &&
        oldVal[0] === 0 &&
        oldVal[1] === 250
      )
        this.filters.numberFilters++;
      if (val[0] === 0 && val[1] === 250) this.filters.numberFilters--;
    },
    "filters.category"(val, oldVal) {
      if (val !== "both" && oldVal === "both") this.filters.numberFilters++;
      if (val === "both") this.filters.numberFilters--;
    },
    "filters.flowOrigin"(val, oldVal) {
      if (val !== oldVal && oldVal == null) this.filters.numberFilters++;
      if (val === null) this.filters.numberFilters--;
    },
  },
  setup() {
    const { fetchSuggestAddress } = useRealestateAPI();

    return {
      fetchSuggestAddress,
    };
  },
  created() {
    // ADD DEBOUNCE TIMER FOR PERFORMANCE ISSUES
    this.debouncedFetchSuggestions = _.debounce(this.fetchSuggestions, 500);
  },
  methods: {
    currency,
    openModalFilters(bvEvent) {
      bvEvent.preventDefault();
      this.$refs["modal-list-filter"].show();
    },
    // METHODS FOR FILTERS
    evaluateLabelPrice() {
      if (this.filters.minPrice && !this.filters.maxPrice) {
        return `${this.currency(Number(this.filters.minPrice))}(.min)`;
      }
      if (this.filters.maxPrice && !this.filters.minPrice) {
        return `${this.currency(Number(this.filters.maxPrice))}(.max)`;
      }
      if (this.filters.maxPrice && this.filters.minPrice) {
        return `${this.currency(
          Number(this.filters.minPrice)
        )} - ${this.currency(Number(this.filters.maxPrice))}`;
      }
    },
    typesSelected(optionSelected) {
      const oldSize = this.filters.types.length;
      const index = this.filters.types.indexOf(optionSelected);
      if (index > -1) {
        this.filters.types.splice(index, 1);
        this.checkNumberFilters(this.filters.types.length, oldSize);
        return;
      }
      this.filters.types.push(optionSelected);
      this.checkNumberFilters(this.filters.types.length, oldSize);
    },
    roomsSelected(optionSelected) {
      const oldSize = this.filters.rooms.length;
      const index = this.filters.rooms.indexOf(optionSelected);
      if (index > -1) {
        this.filters.rooms.splice(index, 1);
        this.checkNumberFilters(this.filters.rooms.length, oldSize);
        return;
      }
      this.filters.rooms.push(optionSelected);
      this.checkNumberFilters(this.filters.rooms.length, oldSize);
    },
    bedroomsSelected(optionSelected) {
      const oldSize = this.filters.bedRooms.length;
      const index = this.filters.bedRooms.indexOf(optionSelected);
      if (index > -1) {
        this.filters.bedRooms.splice(index, 1);
        this.checkNumberFilters(this.filters.bedRooms.length, oldSize);
        return;
      }
      this.filters.bedRooms.push(optionSelected);
      this.checkNumberFilters(this.filters.bedRooms.length, oldSize);
    },
    modeSelected(mode) {
      this.filters.searchMode = mode;
    },
    checkNumberFilters(size, oldSize) {
      if (size > 0 && oldSize === 0) this.filters.numberFilters++;
      if (size === 0) this.filters.numberFilters--;
    },
    // METHODS FOR ADDRESS AUTOSUGGEST
    onBlur() {
      // Force suggestion selection or reset field
      if (
        this.suggestedAddressOptions.length > 0 &&
        this.suggestedAddressOptions[0].data.filter(
          (sugg) => sugg.properties.label === this.filters.address
        ).length > 0
      ) {
        this.lastSelectedAddress = this.filters.address;
        return;
      }
      this.filters.address = this.lastSelectedAddress;
    },
    getSuggestionValue(suggestion) {
      return suggestion.item.properties.label;
    },
    onSelected(item) {
      this.lastSelectedAddress = item.item.properties.label;
      this.filters.center = latLng(
        item.item.geometry.coordinates[1],
        item.item.geometry.coordinates[0]
      );
      this.suggestedAddressOptions = [];
    },
    fetchSuggestions(text) {
      this.fetchSuggestAddress(text).then((response) => {
        this.suggestedAddressOptions = [
          {
            data: response.data.features,
          },
        ];
      });
    },
    onInputChange(text) {
      if (text === "" || text === undefined) {
        return;
      }
      this.debouncedFetchSuggestions(text);
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-autosuggest.scss";
@import "@core/scss/vue/libs/vue-slider.scss";

.modal-height {
  height: auto !important;
}
</style>

<style scoped>
.dropdown-menu {
  width: 19rem !important;
}

.input-group-text {
  border: 1px solid #d8d6de !important;
  font-size: 1rem !important;
  height: auto !important;
}
</style>
