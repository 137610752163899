<template>
  <div>
    <l-map
      :zoom.sync="zoom"
      :center.sync="filters.center"
      :bounds="filters.bounds"
      @update:bounds="boundsUpdated"
    >
      <l-icon-default></l-icon-default>
      <l-control-fullscreen position="topleft" />
      <l-tile-layer :url="url" :attribution="attribution" />
      <l-marker-cluster :options="clusterOptions" ref="cluster">
        <l-marker
          v-for="cluster in clusters"
          :key="cluster.itemIds[0]"
          :lat-lng="computeLatLng(cluster.lat, cluster.lng)"
          :options="{ count: cluster.singlePoint ? 1 : cluster.count }"
          :icon="icon"
          @click="
            cluster.singlePoint
              ? openPopup($event, cluster)
              : zoomOnPoint($event, cluster)
          "
          @popupclose="onPopupClose()"
        >
          <l-popup v-if="cluster.singlePoint">
            <swiper
              id="swiper"
              class="swiper-navigations"
              :options="swiperOptions"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            >
              <swiper-slide
                v-for="(property, index) in propertiesPopedUp"
                :key="index"
              >
                <b-card
                  class="mb-0"
                  no-body
                  @click="
                    newTabTo(
                      $router.resolve({
                        name: `realestate-property`,
                        query: {
                          propertyID: property.id,
                        },
                      }).href
                    )
                  "
                >
                  <img
                    :src="propertyImage(property)"
                    alt="card img"
                    class="card-popup-img"
                    @error="imageLoadError(property.id)"
                  />
                  <b-card-body>
                    <b-card-title class="font-weight-bolder mb-0"
                      >{{ translateTypeParent(property.type_parent) }} -
                      {{ property.rooms.nb_rooms }}p -
                      {{ property.area.area }}m²
                    </b-card-title>
                    <b-card-text class="font-weight-bolder mt-1">
                      {{ currency(property.price) }} -
                      {{ currency(property.price / property.area.area) }}/m²
                    </b-card-text>
                  </b-card-body>
                </b-card>
              </swiper-slide>

              <!-- Add Arrows -->
              <div slot="button-next" class="swiper-button-next" />
              <div slot="button-prev" class="swiper-button-prev" />
            </swiper>
          </l-popup>
        </l-marker>
      </l-marker-cluster>
    </l-map>
  </div>
</template>

<script>
import Vue from "vue";

import { BCard, BCardBody, BCardText, BCardTitle } from "bootstrap-vue";
import { LMap, LTileLayer, LMarker, LPopup, LIconDefault } from "vue2-leaflet";

// Slider Components
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/css/swiper.css";

// Leaflet Elements
import "leaflet/dist/leaflet.css";
import LControlFullscreen from "vue2-leaflet-fullscreen";
import iconUrl from "leaflet/dist/images/marker-icon.png";
import shadowUrl from "leaflet/dist/images/marker-shadow.png";
import { latLng, Icon, icon, divIcon, point } from "leaflet";

// Custom Map Elements
import Vue2LeafletMarkercluster from "./map/Vue2LeafletMarkercluster";
import PropertyIcon from "./map/PropertyIcon";

// Internal Vue Components
import { useRealestateAPI } from "../useRealestate";
import { currency } from "@core/utils/filter";

const EnhancedPropertyIcon = Vue.extend(PropertyIcon);

export default {
  components: {
    LPopup,
    LMarker,
    LIconDefault,
    LMap,
    LTileLayer,
    BCard,
    BCardBody,
    BCardText,
    BCardTitle,
    LControlFullscreen,
    "l-marker-cluster": Vue2LeafletMarkercluster,
    Swiper,
    SwiperSlide,
  },
  props: {
    filters: {
      type: Object,
      required: true,
    },
    clusters: {
      type: Array,
      required: false,
    },
  },
  setup() {
    const { getPropertiesByIDs } = useRealestateAPI();

    return {
      getPropertiesByIDs,
    };
  },
  data() {
    const customicon = icon(
      Object.assign({}, Icon.Default.prototype.options, { iconUrl, shadowUrl })
    );
    return {
      propertiesPopedUp: [],
      icon: customicon,
      clusterOptions: {
        disableClusteringAtZoom: 1,
        singleMarkerMode: true,
        iconCreateFunction: (cluster) => {
          const clusterCount = cluster.getAllChildMarkers()[0].options.count;

          let c = "";
          if (clusterCount === 1) {
            const clusterIconEl = new EnhancedPropertyIcon({
              count: cluster.getAllChildMarkers()[0].options.count.toString(),
            }).$mount().$el;
            return divIcon({
              html: clusterIconEl.outerHTML,
              className: "cluster",
              iconSize: null,
            });
          } else if (clusterCount < 20) {
            c += " marker-cluster-small";
          } else if (clusterCount < 200) {
            c += " marker-cluster-medium";
          } else {
            c += " marker-cluster-large";
          }

          return divIcon({
            html: `<div><span>${clusterCount}</span></div>`,
            className: `marker-cluster${c}`,
            iconSize: new point(40, 40),
          });
        },
      },
      zoom: 13,
      url: this.$config.VUE_APP_MAP_TILE_URL,
      attribution:
        '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
      swiperOptions: {
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      },
    };
  },
  methods: {
    currency,
    newTabTo(pageRedirectURL) {
      window.open(pageRedirectURL);
    },
    propertyImage(property) {
      if (property.images && property.images.length > 0) {
        return property.images[0];
      }
      return this.$config.VUE_APP_IMG_NOT_AVAILABLE;
    },
    imageLoadError(propertyID) {
      if (this.propertiesPopedUp && this.propertiesPopedUp.length > 0) {
        const property = this.propertiesPopedUp.find(
          (property) => property.id === propertyID
        );
        property.images = new Array(this.$config.VUE_APP_IMG_404);
      }
    },
    openPopup(event, cluster) {
      const popup = event.target.getPopup();
      popup.setContent(
        '<p style="height:100px; width:100px">Chargement ...</p>'
      );
      this.getPropertiesByIDs(cluster.itemIds).then((httpResp) => {
        this.propertiesPopedUp = httpResp.data.properties;
        const popup = event.target.getPopup();
        const swiper = document.getElementById("swiper");
        popup.setContent(swiper);
      });
    },
    zoomOnPoint(event, cluster) {
      this.filters.center = latLng(cluster.lat, cluster.lng);
      setTimeout(() => {
        if (this.zoom < 16) this.zoom = this.zoom + 2;
      }, 250);
    },
    onPopupClose() {
      this.propertiesPopedUp = null;
    },
    translateTypeParent(typeParent) {
      if (typeParent === "house") return "Maison";
      if (typeParent === "apartment") return "Appartement";
      if (typeParent === "local") return "Local/Atelier";
      if (typeParent === "field") return "Terrain";
      if (typeParent === "parking") return "Parking";
      return "Logement";
    },
    boundsUpdated(bounds) {
      this.filters.bounds = bounds;
    },
    computeLatLng(lat, lng) {
      return latLng(lat, lng);
    },
  },
};
</script>

<style lang="scss">
@import "~leaflet/dist/leaflet.css";
@import "~leaflet.markercluster/dist/MarkerCluster.css";
@import "~leaflet.markercluster/dist/MarkerCluster.Default.css";

.card-popup-img {
  height: 12rem;
  width: 20rem;
}

.swiper-navigations {
  width: 20rem;
}

.leaflet-container {
  z-index: 1;
}
</style>
