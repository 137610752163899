<template>
  <b-card class="scroll-card">
    <b-overlay :show="isBusy" no-wrap opacity="0.4" rounded="lg">
      <template v-slot:overlay>
        <div class="d-flex align-items-center">
          <b-spinner small type="grow" variant="dark" />
          <b-spinner type="grow" variant="primary" />
          <b-spinner small type="grow" variant="dark" />
          <!-- We add an SR only text for screen readers -->
          <span class="sr-only">Chargement wait...</span>
        </div>
      </template>
    </b-overlay>

    <vue-perfect-scrollbar
      ref="card-scroll"
      :settings="perfectScrollbarSettings"
    >
      <div v-if="!isBusy">
        <b-alert
          variant="primary"
          :show="!properties || properties.length === 0"
          class="mx-2"
        >
          <h4 class="alert-heading">
            Aucune annonce disponible dans la zone de recherche.
          </h4>
        </b-alert>
      </div>

      <div v-if="!!properties && properties.length > 0">
        <b-card
          v-for="property in properties"
          :key="property.id"
          text-variant="white"
          class="mx-1 mb-1 mt-1 mooving-card"
          no-body
          @click="
            newTabTo(
              $router.resolve({
                name: `realestate-property`,
                query: {
                  propertyID: property.id,
                },
              }).href
            )
          "
        >
          <img
            v-if="property.images && property.images.length > 0"
            :src="property.images[0]"
            alt="card img"
            class="scroll-card-img"
            @error="imageLoadError(property.id)"
          />
          <b-card-body class="card-img-overlay bg-overlay">
            <b-card-title class="text-white font-weight-bolder mb-0 ml-1"
              >{{ translateTypeParent(property.type_parent) }}
              {{
                property.type_parent === "house" ||
                property.type_parent === "apartment"
                  ? property.nb_rooms + "p"
                  : null
              }}
              {{ property.area }}m²</b-card-title
            >
            <b-card-text class="text-white font-weight-bolder ml-1">
              {{ property.address.city }}
            </b-card-text>
            <b-card-text class="text-white font-weight-bolder ml-1">
              {{ currency(property.price) }}
              <p class="text-muted">
                {{ currency(property.price / property.area) }}/m²
              </p>
            </b-card-text>
          </b-card-body>
        </b-card>
      </div>
    </vue-perfect-scrollbar>
  </b-card>
</template>

<script>
import {
  BCard,
  BCardBody,
  BCardText,
  BCardTitle,
  BOverlay,
  BSpinner,
  BAlert,
} from "bootstrap-vue";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import { currency } from "@core/utils/filter";

export default {
  components: {
    BCard,
    BCardBody,
    BCardText,
    BCardTitle,
    BOverlay,
    BSpinner,
    BAlert,

    // 3rd party
    VuePerfectScrollbar,
  },
  props: {
    filters: {
      type: Object,
      required: true,
    },
    page: {
      type: Number,
      required: true,
    },
    isBusy: {
      type: Boolean,
      required: true,
    },
    properties: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      perfectScrollbarSettings: {
        maxScrollbarLength: 150,
        wheelPropagation: false,
      },
    };
  },
  mounted() {
    const tableScrollBody = this.$refs["card-scroll"].$el;
    /* Consider debouncing the event call */
    tableScrollBody.addEventListener("scroll", this.onScroll);
  },
  beforeDestroy() {
    /* Clean up just to be sure */
    const tableScrollBody = this.$refs["card-scroll"].$el;
    tableScrollBody.removeEventListener("scroll", this.onScroll);
  },
  methods: {
    currency,
    newTabTo(pageRedirectURL) {
      window.open(pageRedirectURL);
    },
    imageLoadError(propertyID) {
      const property = this.properties.find(
        (property) => property.id === propertyID
      );
      property.images = [this.$config.VUE_APP_IMG_404];
    },
    translateTypeParent(typeParent) {
      if (typeParent === "house") return "Maison";
      if (typeParent === "apartment") return "Appartement";
      if (typeParent === "local") return "Local/Atelier";
      if (typeParent === "field") return "Terrain";
      if (typeParent === "parking") return "Parking";
      return "Logement";
    },
    onScroll(event) {
      if (
        event.target.scrollTop + event.target.clientHeight >=
        event.target.scrollHeight
      ) {
        if (!this.isBusy) {
          this.$emit("scrollBottomUpdated", this.page + 1);
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.scroll-card-img {
  max-height: 110px;
  object-fit: cover;
}

.card-body {
  padding-right: 0px !important;
  padding-left: 0px !important;
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}

.bg-overlay {
  background: rgba(black, 0.25) !important;
}

.mooving-card {
  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 4px 25px 0 rgba(black, 0.55);
  }
}

.scroll-card {
  height: 80%;
}

.ps-container {
  max-height: 97%;
  position: absolute;
  width: 100%;
}
</style>
