<template>
  <div class="content-right-match-height">
    <map-card :filters="filters" :clusters="clusters" class="h-100" />

    <!-- Sidebar -->
    <portal to="content-renderer-sidebar-detached-left">
      <div class="sidebar-detached sidebar-left">
        <div class="sidebar">
          <div class="sidebar-shop" :class="{ show: mqShallShowLeftSidebar }">
            <select-card :filters="filters" :filter-options="filterOptions" />
            <properties-scroll
              @scrollBottomUpdated="scrollBottomUpdated"
              :filters="filters"
              :properties="properties"
              :page="filters.page"
              :isBusy="isBusy"
            />
          </div>
        </div>

        <div
          class="body-content-overlay"
          :class="{ show: mqShallShowLeftSidebar }"
          @click="$emit('update:mq-shall-show-left-sidebar', false)"
        />
      </div>
    </portal>
  </div>
</template>

<script>
import _ from "lodash";

// Internal Vue Components
import { useRealestateAPI } from "../useRealestate";
import MapCard from "./MapCard.vue";
import SelectCard from "./SelectCard.vue";
import PropertiesScroll from "./PropertiesScroll.vue";

export default {
  components: {
    // Internal Components
    MapCard,
    SelectCard,
    PropertiesScroll,
  },
  props: {
    filters: {
      type: Object,
      required: true,
    },
    filterOptions: {
      type: Object,
      required: true,
    },
    totalProperties: {
      type: Object,
      required: true,
    },
    mqShallShowLeftSidebar: {
      type: Boolean,
      required: true,
    },
  },
  watch: {
    "filters.bounds"(val, oldVal) {
      if (val !== oldVal) this.debouncedFetchedPropertiesFiltered();
    },
    "filters.category"() {
      this.debouncedFetchedPropertiesFiltered();
    },
    "filters.types"() {
      this.debouncedFetchedPropertiesFiltered();
    },
    "filters.minPrice"() {
      this.debouncedFetchedPropertiesFiltered();
    },
    "filters.maxPrice"() {
      this.debouncedFetchedPropertiesFiltered();
    },
    "filters.rooms"() {
      this.debouncedFetchedPropertiesFiltered();
    },
    "filters.bedRooms"() {
      this.debouncedFetchedPropertiesFiltered();
    },
    "filters.areas"() {
      this.debouncedFetchedPropertiesFiltered();
    },
    "filters.searchMode"() {
      this.debouncedFetchedPropertiesFiltered();
    },
    "filters.flowOrigin"() {
      this.debouncedFetchedPropertiesFiltered();
    },
  },
  setup(props) {
    const { listClusters, listProperties, properties, clusters, isBusy } =
      useRealestateAPI();

    const fetchPropertiesMapAndGrid = (filters) => {
      isBusy.value = true;
      listProperties(filters)
        .then((httpResp) => {
          isBusy.value = false;
          if (httpResp.data.properties !== null) {
            props.totalProperties.count = httpResp.data.count;
            properties.value = httpResp.data.properties;
          } else {
            props.totalProperties.count = 0;
            properties.value.splice(0, properties.value.length);
          }
        })
        .catch(() => {
          isBusy.value = false;
        });
      listClusters(filters)
        .then((httpResp) => {
          isBusy.value = false;
          clusters.value = httpResp.data.clusters;
        })
        .catch(() => {
          isBusy.value = false;
        });
    };

    const fetchPropertiesGrid = (filters) => {
      isBusy.value = true;
      listProperties(filters)
        .then((httpResp) => {
          isBusy.value = false;
          if (httpResp.data.properties !== null) {
            properties.value = properties.value.concat(
              httpResp.data.properties
            );
          }
        })
        .catch(() => {
          isBusy.value = false;
        });
    };

    return {
      isBusy,
      properties,
      clusters,

      fetchPropertiesMapAndGrid,
      fetchPropertiesGrid,
    };
  },
  created() {
    this.debouncedFetchedPropertiesFiltered = _.debounce(
      this.fetchPropertiesFiltered,
      600
    );

    // Reinit page
    this.filters.page = 1;
    this.debouncedFetchedPropertiesFiltered();
  },
  methods: {
    scrollBottomUpdated(page) {
      this.filters.page = page;
      this.fetchPropertiesGrid(this.filters);
    },
    fetchPropertiesFiltered() {
      this.filters.page = 1;
      this.fetchPropertiesMapAndGrid(this.filters);
    },
  },
};
</script>

<style lang="scss" scoped>
.card {
  margin-bottom: 0.5rem !important;
}

.content-right-match-height {
  height: 92%;
}
</style>
