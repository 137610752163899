<template>
  <div class="h-100">
    <!-- ECommerce Header -->
    <section id="itemgrid-header">
      <div class="row mb-1">
        <div class="col-sm-12">
          <div class="itemgrid-header-items mt-50">
            <div class="result-toggler">
              <feather-icon
                icon="SearchIcon"
                class="d-block d-lg-none"
                size="21"
                @click="mqShallShowLeftSidebar = true"
              />
              <div class="search-results">
                {{ totalProperties.count }} Annonces
              </div>
            </div>
            <div class="view-options d-flex">
              <!-- Item View Radio Button Group  -->
              <b-form-radio-group
                v-model="itemView"
                class="ml-1 list item-view-radio-group"
                buttons
                size="sm"
                button-variant="outline-primary"
              >
                <b-form-radio
                  v-for="option in itemViewOptions"
                  :key="option.value"
                  :value="option.value"
                >
                  <feather-icon :icon="option.icon" size="18" />
                </b-form-radio>
              </b-form-radio-group>
            </div>
          </div>
        </div>
      </div>
    </section>

    <searcher-map
      v-if="!itemView"
      :filters="filters"
      :filterOptions="filterOptions"
      :totalProperties="totalProperties"
      :mq-shall-show-left-sidebar.sync="mqShallShowLeftSidebar"
    ></searcher-map>
    <searcher-list
      v-else
      :filters="filters"
      :filterOptions="filterOptions"
      :totalProperties="totalProperties"
      :mq-shall-show-left-sidebar.sync="mqShallShowLeftSidebar"
    ></searcher-list>
  </div>
</template>

<script>
import store from "@/store";
import { onUnmounted } from "@vue/composition-api";
import { BFormRadioGroup, BFormRadio } from "bootstrap-vue";
import { useResponsiveAppLeftSidebarVisibility } from "@core/comp-functions/ui/app";

// Internal Vue Components
import {
  useRealestateUi,
  useRealestateFiltersAndSorting,
} from "./useRealestate";
import realestateStoreModule from "../realestateStoreModule";
import SearcherMap from "./map-searcher/SearcherMap.vue";
import SearcherList from "./list-searcher/SearcherList.vue";

export default {
  components: {
    BFormRadioGroup,
    BFormRadio,

    // Internal Components
    SearcherMap,
    SearcherList,
  },
  setup() {
    const { mqShallShowLeftSidebar } = useResponsiveAppLeftSidebarVisibility();

    const { itemView, itemViewOptions, totalProperties } = useRealestateUi();

    const { filters, filterOptions, fetchFlowsOrigins } =
      useRealestateFiltersAndSorting();

    const REALESTATE_APP_STORE_MODULE_NAME = "app-realestate";

    // Register module
    if (!store.hasModule(REALESTATE_APP_STORE_MODULE_NAME))
      store.registerModule(
        REALESTATE_APP_STORE_MODULE_NAME,
        realestateStoreModule
      );

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(REALESTATE_APP_STORE_MODULE_NAME))
        store.unregisterModule(REALESTATE_APP_STORE_MODULE_NAME);
    });

    fetchFlowsOrigins();

    return {
      filters,
      filterOptions,
      itemView,
      itemViewOptions,
      totalProperties,

      mqShallShowLeftSidebar,
    };
  },
};
</script>

<style lang="scss">
@import "~@core/scss/base/pages/app-itemgrid.scss";
</style>

<style lang="scss" scoped>
.item-view-radio-group ::v-deep {
  .btn {
    display: flex;
    align-items: center;
  }
}
</style>
