var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',{staticClass:"scroll-card"},[_c('b-overlay',{attrs:{"show":_vm.isBusy,"no-wrap":"","opacity":"0.4","rounded":"lg"},scopedSlots:_vm._u([{key:"overlay",fn:function(){return [_c('div',{staticClass:"d-flex align-items-center"},[_c('b-spinner',{attrs:{"small":"","type":"grow","variant":"dark"}}),_c('b-spinner',{attrs:{"type":"grow","variant":"primary"}}),_c('b-spinner',{attrs:{"small":"","type":"grow","variant":"dark"}}),_c('span',{staticClass:"sr-only"},[_vm._v("Chargement wait...")])],1)]},proxy:true}])}),_c('vue-perfect-scrollbar',{ref:"card-scroll",attrs:{"settings":_vm.perfectScrollbarSettings}},[(!_vm.isBusy)?_c('div',[_c('b-alert',{staticClass:"mx-2",attrs:{"variant":"primary","show":!_vm.properties || _vm.properties.length === 0}},[_c('h4',{staticClass:"alert-heading"},[_vm._v(" Aucune annonce disponible dans la zone de recherche. ")])])],1):_vm._e(),(!!_vm.properties && _vm.properties.length > 0)?_c('div',_vm._l((_vm.properties),function(property){return _c('b-card',{key:property.id,staticClass:"mx-1 mb-1 mt-1 mooving-card",attrs:{"text-variant":"white","no-body":""},on:{"click":function($event){_vm.newTabTo(
            _vm.$router.resolve({
              name: "realestate-property",
              query: {
                propertyID: property.id,
              },
            }).href
          )}}},[(property.images && property.images.length > 0)?_c('img',{staticClass:"scroll-card-img",attrs:{"src":property.images[0],"alt":"card img"},on:{"error":function($event){return _vm.imageLoadError(property.id)}}}):_vm._e(),_c('b-card-body',{staticClass:"card-img-overlay bg-overlay"},[_c('b-card-title',{staticClass:"text-white font-weight-bolder mb-0 ml-1"},[_vm._v(_vm._s(_vm.translateTypeParent(property.type_parent))+" "+_vm._s(property.type_parent === "house" || property.type_parent === "apartment" ? property.nb_rooms + "p" : null)+" "+_vm._s(property.area)+"m²")]),_c('b-card-text',{staticClass:"text-white font-weight-bolder ml-1"},[_vm._v(" "+_vm._s(property.address.city)+" ")]),_c('b-card-text',{staticClass:"text-white font-weight-bolder ml-1"},[_vm._v(" "+_vm._s(_vm.currency(property.price))+" "),_c('p',{staticClass:"text-muted"},[_vm._v(" "+_vm._s(_vm.currency(property.price / property.area))+"/m² ")])])],1)],1)}),1):_vm._e()])],1)}
var staticRenderFns = []

export { render, staticRenderFns }