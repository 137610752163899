<template>
  <div style="height: inherit">
    <!-- Overlay -->
    <b-overlay :show="isBusy" no-wrap opacity="0.4" rounded="lg">
      <template v-slot:overlay>
        <div class="d-flex align-items-center">
          <b-spinner small type="grow" variant="dark" />
          <b-spinner type="grow" variant="primary" />
          <b-spinner small type="grow" variant="dark" />
          <!-- We add an SR only text for screen readers -->
          <span class="sr-only">Chargement wait...</span>
        </div>
      </template>
    </b-overlay>

    <!-- Prodcuts -->
    <section class="grid-view mt-0">
      <b-card
        v-for="property in properties"
        :key="property.id"
        class="itemgrid-card"
        no-body
        @click="
          newTabTo(
            $router.resolve({
              name: `realestate-property`,
              query: {
                propertyID: property.id,
              },
            }).href
          )
        "
      >
        <div>
          <b-link>
            <b-img
              v-if="property.images && property.images.length > 0"
              :src="property.images[0]"
              :alt="`${property.id}`"
              fluid
              class="list-card-img"
              @error="imageLoadError(property.id)"
            />
          </b-link>
        </div>

        <!-- Product Details -->
        <b-card-body>
          <div class="item-wrapper">
            <div class="item-rating">
              <h6 class="item-price">
                {{ currency(property.price) }}
              </h6>
            </div>
            <div>
              <span> {{ currency(property.price / property.area) }}/m² </span>
            </div>
          </div>
          <div class="item-wrapper">
            <div class="item-rating">
              <b-badge variant="light-success" class="mr-1">
                {{ property.area }} m²
              </b-badge>
              <b-badge variant="light-primary" class="ml-25">
                {{ property.nb_rooms }}
                {{ property.nb_rooms > 1 ? "pièces" : "pièce" }}
              </b-badge>
            </div>
            <div>
              <span>
                {{ property.created_at.slice(0, 10) }}
              </span>
            </div>
          </div>
        </b-card-body>
      </b-card>
    </section>

    <!-- Pagination -->
    <section>
      <b-row class="mt-1">
        <b-col cols="12">
          <b-pagination
            v-model="filters.page"
            :total-rows="totalProperties.count"
            :per-page="filters.perPage"
            first-number
            align="center"
            last-number
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon icon="ChevronLeftIcon" size="18" />
            </template>
            <template #next-text>
              <feather-icon icon="ChevronRightIcon" size="18" />
            </template>
          </b-pagination>
        </b-col>
      </b-row>
    </section>

    <!-- Sidebar -->
    <portal to="content-renderer-sidebar-detached-left">
      <div class="sidebar-detached sidebar-left">
        <div class="sidebar">
          <div class="sidebar-shop" :class="{ show: mqShallShowLeftSidebar }">
            <filter-card :filters="filters" :filter-options="filterOptions" />
          </div>
        </div>

        <div
          class="body-content-overlay"
          :class="{ show: mqShallShowLeftSidebar }"
          @click="$emit('update:mq-shall-show-left-sidebar', false)"
        />
      </div>
    </portal>
  </div>
</template>

<script>
import {
  BOverlay,
  BSpinner,
  BRow,
  BCol,
  BCard,
  BCardBody,
  BBadge,
  BLink,
  BImg,
  BPagination,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { currency } from "@core/utils/filter";
import { useRealestateAPI } from "../useRealestate";
import _ from "lodash";
import FilterCard from "./FilterCard.vue";

export default {
  directives: {
    Ripple,
  },
  components: {
    BOverlay,
    BSpinner,
    BRow,
    BCol,
    BCard,
    BCardBody,
    BBadge,
    BLink,
    BImg,
    BPagination,

    // Filters Card
    FilterCard,
  },
  props: {
    filters: {
      type: Object,
      required: true,
    },
    filterOptions: {
      type: Object,
      required: true,
    },
    totalProperties: {
      type: Object,
      required: true,
    },
    mqShallShowLeftSidebar: {
      type: Boolean,
      required: true,
    },
  },
  watch: {
    "filters.bounds"() {
      this.debouncedFetchedPropertiesGrid();
    },
    "filters.page"() {
      this.debouncedFetchedPropertiesGrid();
    },
    "filters.category"() {
      this.debouncedFetchedPropertiesGrid();
    },
    "filters.types"() {
      this.debouncedFetchedPropertiesGrid();
    },
    "filters.minPrice"() {
      this.debouncedFetchedPropertiesGrid();
    },
    "filters.maxPrice"() {
      this.debouncedFetchedPropertiesGrid();
    },
    "filters.rooms"() {
      this.debouncedFetchedPropertiesGrid();
    },
    "filters.bedRooms"() {
      this.debouncedFetchedPropertiesGrid();
    },
    "filters.areas"() {
      this.debouncedFetchedPropertiesGrid();
    },
    "filters.searchMode"() {
      this.debouncedFetchedPropertiesGrid();
    },
    "filters.flowOrigin"() {
      this.debouncedFetchedPropertiesGrid();
    },
  },
  setup(props) {
    const { listProperties, properties, isBusy } = useRealestateAPI();

    const fetchPropertiesGrid = (filters) => {
      isBusy.value = true;
      listProperties(filters)
        .then((httpResp) => {
          isBusy.value = false;
          if (httpResp.data.properties !== null) {
            props.totalProperties.count = httpResp.data.count;
            properties.value = httpResp.data.properties;
          }
        })
        .catch(() => {
          isBusy.value = false;
        });
    };

    return {
      properties,
      isBusy,

      fetchPropertiesGrid,
    };
  },
  created() {
    this.debouncedFetchedPropertiesGrid = _.debounce(
      this.fetchPropertiesFiltered,
      600
    );

    this.filters.page = 1;
    this.fetchPropertiesFiltered();
  },
  methods: {
    currency,
    newTabTo(pageRedirectURL) {
      window.open(pageRedirectURL);
    },
    imageLoadError(propertyID) {
      const property = this.properties.find(
        (property) => property.id === propertyID
      );
      property.images = [this.$config.VUE_APP_IMG_404];
    },
    fetchPropertiesFiltered() {
      this.fetchPropertiesGrid(this.filters);
    },
  },
};
</script>

<style lang="scss">
@import "~@core/scss/base/pages/app-itemgrid.scss";

.list-card-img {
  max-height: 170px;
  width: 100%;
  object-fit: cover;
}
</style>

<style lang="scss" scoped>
.item-view-radio-group ::v-deep {
  .btn {
    display: flex;
    align-items: center;
  }
}

.card {
  margin-bottom: 1rem !important;
}
</style>
