<template>
  <!-- Filters' Card -->
  <b-card body-class="py-1">
    <b-row>
      <b-col cols="12">
        <b-form-group
          label="Rechercher une adresse"
          label-for="selected"
          class="mb-50"
        >
          <vue-autosuggest
            id="selected"
            :suggestions="suggestedAddressOptions"
            :limit="4"
            :input-props="{
              id: 'autosuggest__input',
              class: 'form-control',
              placeholder: '51, Rue du Marché 69300 Caluire-et-Cuire',
            }"
            @input="onInputChange"
            @selected="onSelected"
            @blur="onBlur"
            v-model="filters.address"
          >
            <template slot-scope="{ suggestion }">
              <span class="my-suggestion-item">{{
                suggestion.item.properties.label
              }}</span>
            </template>
          </vue-autosuggest>
        </b-form-group>
      </b-col>
    </b-row>

    <label>Flux</label>
    <v-select
      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
      v-model="filters.flowOrigin"
      :options="filterOptions.flowOriginOptions"
      :clearable="true"
      class="w-100 mb-50"
    />

    <label>Distance (km)</label>
    <vue-slider
      v-model="filters.distance"
      :tooltip="'none'"
      :adsorb="true"
      :data="filterOptions.distanceOptions"
      :marks="true"
      class="mb-3 mr-4 ml-4"
    />

    <hr />

    <b-row>
      <b-col cols="4">
        <label>Projet</label>
        <b-form-radio-group
          v-model="filters.searchMode"
          :options="filterOptions.searchModeOptions"
          value-field="value"
          text-field="label"
        />
      </b-col>
      <b-col cols="8">
        <b-form-group label="Prix minimum" label-for="minPrice">
          <b-input-group prepend="€">
            <b-form-input
              placeholder="120 000"
              type="number"
              v-model="filters.minPrice"
            />
          </b-input-group>
        </b-form-group>

        <b-form-group label="Prix maximum" label-for="maxPrice">
          <b-input-group prepend="€">
            <b-form-input
              placeholder="240 000"
              type="number"
              v-model="filters.maxPrice"
            />
          </b-input-group>
        </b-form-group>
      </b-col>
    </b-row>

    <hr />

    <!-- Type Property -->
    <label>Type</label>
    <b-row class="match-height">
      <b-col v-for="option in filterOptions.typesOptions" :key="option.value">
        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          :variant="
            filters.types.includes(option.value) ? 'primary' : 'outline-primary'
          "
          @click="typesSelected(option.value)"
          class="my-50 px-50"
        >
          <feather-icon size="16" :icon="option.icon" class="mr-50" />
          <span>{{ option.label }}</span>
        </b-button>
      </b-col>
    </b-row>

    <hr />

    <label>Surface</label>
    <vue-slider
      v-model="filters.areas"
      :order="true"
      :tooltip="'always'"
      :min="0"
      :max="250"
      :min-range="20"
      class="mt-3 mb-1 mr-4 ml-4"
      :tooltip-formatter="formatter"
    />

    <hr />

    <b-row>
      <b-col cols="12">
        <label>Nombre de pièces</label>
        <b-button-group class="mr-1 ml-1" size="md">
          <b-button
            v-for="option in filterOptions.roomsOptions"
            :key="option.value"
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            :variant="
              filters.rooms.includes(option.value)
                ? 'primary'
                : 'outline-primary'
            "
            @click="roomsSelected(option.value)"
          >
            <span>{{ option.label }}</span>
          </b-button>
        </b-button-group>
      </b-col>
      <b-col cols="12" class="mt-1">
        <label>Nombre de chambres</label>
        <b-button-group class="mr-1 ml-1" size="md">
          <b-button
            v-for="option in filterOptions.roomsOptions"
            :key="option.value"
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            :variant="
              filters.bedRooms.includes(option.value)
                ? 'primary'
                : 'outline-primary'
            "
            @click="bedroomsSelected(option.value)"
          >
            <span>{{ option.label }}</span>
          </b-button>
        </b-button-group>
      </b-col>
    </b-row>

    <hr />

    <label>Catégorie</label>
    <b-form-radio-group
      v-model="filters.category"
      :options="filterOptions.categorieOptions"
      value-field="value"
      text-field="label"
      class="ml-2"
    />
  </b-card>
</template>

<script>
import { VueAutosuggest } from "vue-autosuggest";
import { latLng, latLngBounds } from "leaflet";
import destination from "@turf/destination";
import { useRealestateAPI } from "../useRealestate";
import {
  BRow,
  BCol,
  BCard,
  BFormRadioGroup,
  BFormGroup,
  BFormInput,
  BButtonGroup,
  BInputGroup,
  BButton,
} from "bootstrap-vue";
import vSelect from "vue-select";
import VueSlider from "vue-slider-component";
import Ripple from "vue-ripple-directive";
import _ from "lodash";

export default {
  components: {
    BRow,
    BCol,
    BFormRadioGroup,
    BCard,
    BFormGroup,
    BFormInput,
    BButtonGroup,
    BInputGroup,
    BButton,
    vSelect,

    // 3rd Party
    VueSlider,
    VueAutosuggest,
  },
  directives: {
    Ripple,
  },
  props: {
    filters: {
      type: Object,
      required: true,
    },
    filterOptions: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      lastSelectedAddress: this.filters.address,
      formatter: "{value} m²",
      suggestedAddressOptions: [],
    };
  },
  computed: {
    selected: {
      get() {
        return this.address;
      },
      set(value) {
        this.$emit("address-selected", value);
      },
    },
  },
  watch: {
    "filters.distance"() {
      const northEast = destination(
        [this.filters.center.lng, this.filters.center.lat],
        this.filters.distance,
        45,
        { units: "kilometers" }
      );
      const southWest = destination(
        [this.filters.center.lng, this.filters.center.lat],
        this.filters.distance,
        -135,
        { units: "kilometers" }
      );
      this.filters.bounds = latLngBounds([
        [northEast.geometry.coordinates[1], northEast.geometry.coordinates[0]],
        [southWest.geometry.coordinates[1], southWest.geometry.coordinates[0]],
      ]);
    },
    "filters.areas"(val, oldVal) {
      if (
        (val[0] !== 0 || val[1] !== 250) &&
        oldVal[0] === 0 &&
        oldVal[1] === 250
      )
        this.filters.numberFilters++;
      if (
        val[0] === 0 &&
        val[1] === 250 &&
        (oldVal[0] !== 0 || oldVal[1] !== 250)
      )
        this.filters.numberFilters--;
    },
    "filters.category"(val, oldVal) {
      if (val !== "both" && oldVal === "both") this.filters.numberFilters++;
      if (val === "both") this.filters.numberFilters--;
    },
    "filters.flowOrigin"(val, oldVal) {
      if (val !== oldVal && oldVal == null) this.filters.numberFilters++;
      if (val === null) this.filters.numberFilters--;
    },
  },
  setup() {
    const { fetchSuggestAddress } = useRealestateAPI();

    return {
      fetchSuggestAddress,
    };
  },
  created() {
    // ADD DEBOUNCE TIMER FOR PERFORMANCE ISSUES
    this.debouncedFetchSuggestions = _.debounce(this.fetchSuggestions, 500);
  },
  methods: {
    typesSelected(optionSelected) {
      const oldSize = this.filters.types.length;
      const index = this.filters.types.indexOf(optionSelected);
      if (index > -1) {
        this.filters.types.splice(index, 1);
        this.checkNumberFilters(this.filters.types.length, oldSize);
        return;
      }
      this.filters.types.push(optionSelected);
      this.checkNumberFilters(this.filters.types.length, oldSize);
    },
    roomsSelected(optionSelected) {
      const oldSize = this.filters.rooms.length;
      const index = this.filters.rooms.indexOf(optionSelected);
      if (index > -1) {
        this.filters.rooms.splice(index, 1);
        this.checkNumberFilters(this.filters.rooms.length, oldSize);
        return;
      }
      this.filters.rooms.push(optionSelected);
      this.checkNumberFilters(this.filters.rooms.length, oldSize);
    },
    bedroomsSelected(optionSelected) {
      const oldSize = this.filters.bedRooms.length;
      const index = this.filters.bedRooms.indexOf(optionSelected);
      if (index > -1) {
        this.filters.bedRooms.splice(index, 1);
        this.checkNumberFilters(this.filters.bedRooms.length, oldSize);
        return;
      }
      this.filters.bedRooms.push(optionSelected);
      this.checkNumberFilters(this.filters.bedRooms.length, oldSize);
    },
    checkNumberFilters(size, oldSize) {
      if (size > 0 && oldSize === 0) this.filters.numberFilters++;
      if (size === 0) this.filters.numberFilters--;
    },
    // METHODS FOR ADDRESS AUTOSUGGEST
    onBlur() {
      // Force suggestion selection or reset field
      if (
        this.suggestedAddressOptions.length > 0 &&
        this.suggestedAddressOptions[0].data.filter(
          (sugg) => sugg.properties.label.toString() === this.filters.address
        ).length <= 0
      ) {
        this.filters.address = this.lastSelectedAddress;
      } else if (
        this.filters.address !== this.lastSelectedAddress &&
        this.suggestedAddressOptions.length === 0
      ) {
        this.filters.address = this.lastSelectedAddress;
      }
    },
    onSelected(item) {
      this.lastSelectedAddress = item.item.properties.label.toString();
      this.filters.address = item.item.properties.label.toString();
      this.filters.center = latLng(
        item.item.geometry.coordinates[1],
        item.item.geometry.coordinates[0]
      );
      const northEast = destination(
        item.item.geometry.coordinates,
        this.filters.distance,
        45,
        { units: "kilometers" }
      );
      const southWest = destination(
        item.item.geometry.coordinates,
        this.filters.distance,
        -135,
        { units: "kilometers" }
      );
      this.filters.bounds = latLngBounds([
        [northEast.geometry.coordinates[1], northEast.geometry.coordinates[0]],
        [southWest.geometry.coordinates[1], southWest.geometry.coordinates[0]],
      ]);
    },
    fetchSuggestions(text) {
      this.fetchSuggestAddress(text).then((response) => {
        this.suggestedAddressOptions = [
          {
            data: response.data.features,
          },
        ];
      });
    },
    onInputChange(text) {
      if (text === "" || text === undefined) {
        return;
      }
      this.debouncedFetchSuggestions(text);
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-autosuggest.scss";
@import "@core/scss/vue/libs/vue-slider.scss";
</style>

<style lang="scss" scoped>
.categories-radio-group,
.brands-radio-group,
.price-range-defined-radio-group {
  ::v-deep > .custom-control {
    margin-bottom: 0.75rem;
  }
}

.input-group-text {
  border: 1px solid #d8d6de !important;
  font-size: 1rem !important;
  height: auto !important;
}
</style>
